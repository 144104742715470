import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { IconButton, Icon, Typography } from "@mui/material";

import useAuth from "contexts/AuthContext";
import { useHistory } from "react-router-dom";

const menuItems = [
  {
    name: "Profile",
    icon: "account_circle",
    onClick: (history) => {
      history.push("/profile");
    },
  },
  {
    name: "Chats",
    icon: "message",
    onClick: (history) => {
      history.push("/chats");
    },
  },
  {
    name: "Gallery",
    icon: "image",
    onClick: (history) => {
      history.push("/gallery");
    },
  },
  {
    name: "Dating",
    icon: "favorites",
    onClick: (history) => {
      history.push("/dating");
    },
  },
];

export default function TemporaryDrawer() {
  const { user, logout } = useAuth();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const list = () => (
    <Box sx={{ width: 250, height: "100%", overflow: "hidden" }}>
      <List
        sx={{
          height: "100%",
        }}
      >
        {menuItems.map((item, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton
              onClick={() => {
                item.onClick(history);
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <Icon>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem
          disablePadding
          sx={{
            position: "absolute",
            bottom: "1rem",
          }}
        >
          <ListItemButton
            onClick={() => {
              logout();
              setOpen(false);
              history.push("/");
            }}
          >
            <ListItemIcon>
              <Icon>logout</Icon>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {user && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            backgroundColor: "primary.dark",
            height: "4em",
            zIndex: 100,
          }}
        >
          <Typography sx={{ width: "85%" }} variant="h6">
            {history?.location.pathname == "/chat"
              ? "Messages Left: " + user.credits
              : ""}
          </Typography>

          <IconButton onClick={toggleDrawer(true)}>
            <Icon>menu</Icon>
          </IconButton>
        </Grid>
      )}
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {list()}
      </Drawer>
    </div>
  );
}
