import React, { useState, useEffect, useRef } from "react";

import {
  Grid,
  Dialog,
  Card,
  Typography,
  TextField,
  IconButton,
  Chip,
  Icon,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import AppPageLayout from "components/AppPageLayout";
import Loading from "react-loading-components";

import useAuth from "contexts/AuthContext";
import axios from "utils/axios";
import { normalizeData } from "utils/utils";

function Img({ src, isFavourite }) {
  const { user, refreshUser } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Grid container padding={2} justifyContent="center">
      <Chip
        icon={<Icon sx={{ color: "white" }}>local_fire_department</Icon>}
        label="You received an image!"
        onClick={() => {
          setOpenDialog(true);
          console.log(isFavourite);
        }}
        sx={{ backgroundColor: "secondary.light", color: "white" }}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={{ zIndex: 9999 }}
      >
        <IconButton
          disabled={isFavourite}
          sx={{
            backgroundColor: "#ff6699",
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 9999,
          }}
          onClick={async () => {
            if (isFavourite) {
              await axios.delete("/api/favourite-images/" + isFavourite.id);
              isFavourite = false;
              refreshUser();
            } else {
              await axios.post("/api/favourite-images", {
                data: {
                  url: src,
                  owner: user.id,
                  agent: 1,
                },
              });
              isFavourite = true;
              refreshUser();
            }
          }}
        >
          <Icon sx={{ color: "white" }}>favorite</Icon>
        </IconButton>
        <img
          style={{ width: "100%", objectFit: "contain" }}
          alt="full"
          src={src}
        />
      </Dialog>
    </Grid>
  );
}

const MessageBubble = ({ message, id, isLeft, info, createdAt }) => {
  return (
    <Grid
      container
      padding={2}
      justifyContent={isLeft ? "flex-start" : "flex-end"}
    >
      <Card
        sx={{
          maxWidth: "70%",
          padding: 2,
          borderRadius: 3,
          backgroundColor: isLeft ? "secondary.dark" : "primary.dark",
        }}
        elevation={1}
        // onClick={() => {
        //   alert(JSON.stringify(info, null, 2));
        // }}
      >
        <Typography>{message}</Typography>
      </Card>
      <Grid item xs={12}>
        <Typography align={isLeft ? "left" : "right"}>
          {createdAt.slice(11, 16)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const UserInput = ({ sendMessage, disabled, toggleMsgLoader }) => {
  const [message, setMessage] = useState("");

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        px: 2,
        zIndex: 9999,
      }}
    >
      <Card sx={{ width: "100%", padding: 1, borderRadius: "10px 10px 0 0" }}>
        <Grid container>
          <Grid item xs={10}>
            <TextField
              fullWidth
              disabled={disabled}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  sendMessage(message);
                  setMessage("");
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            container
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              disabled={disabled}
              onClick={() => {
                sendMessage(message);
                setMessage("");
              }}
            >
              <SendIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Chat = () => {
  const { user, refreshUser } = useAuth();

  const chatContainerRef = useRef(null);
  const [chat, setChat] = useState(null);
  const [chatID, setChatID] = useState(localStorage.getItem("chatID"));
  const [inAppMessages, setInAppMessages] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    if (user) loadChatMessages(chatID);
  }, [user, localStorage.getItem("chatID")]);

  useEffect(() => {
    if (inAppMessages.length > 0) {
      chatContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [totalMessages, typing]);

  const addInAppMessage = (Component, props) => {
    let existing = inAppMessages;
    existing.push({ component: Component, props });
    setInAppMessages(existing);
    setTotalMessages(existing.length);
  };

  const addMessage = (message, isUser, info = "", createdAt) => {
    addInAppMessage(MessageBubble, {
      message: message,
      isLeft: !isUser,
      info: info,
      createdAt: createdAt,
    });
  };

  const loadChatMessages = async (id) => {
    let messagesToCache = [];
    const addToCache = (Component, message, isUser, info = "", createdAt) => {
      messagesToCache.push({
        component: Component,
        props: {
          message: message,
          isLeft: !isUser,
          info: info,
          createdAt: createdAt,
        },
      });
    };

    try {
      const res = await axios.get(
        "/api/messages?filters[chat]=" +
          id +
          "&pagination[limit]=20&sort=createdAt:desc"
      );
      const data = normalizeData(res.data);
      data.reverse();
      if (data.length < 20)
        addToCache(
          MessageBubble,
          "Hi, I'm Sam, your naughty AI friend!",
          false,
          "",
          ""
        );
      data.forEach((message) => {
        if (message.type == "text") {
          if (message.agent == "USER")
            addToCache(
              MessageBubble,
              message.payload.message.split("</s>")[0],
              true,
              "",
              message.createdAt
            );
          else
            addToCache(
              MessageBubble,
              message.payload.message.split("</s>")[0],
              false,
              message.payload.status,
              message.createdAt
            );
        }
        if (message.type == "image") {
          messagesToCache.push({
            component: Img,
            props: {
              src: message.payload.url,
              messageID: message.id,
              isFavourite: (user.favourite_images || []).find(
                (x) => x.url == message.payload.url
              ),
            },
          });
        }
      });
      setInAppMessages(messagesToCache);
      // scroll down
      chatContainerRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewUserMessage = async (newMessage) => {
    if (newMessage.trim().length == 0) return;
    addMessage(newMessage, true, "", new Date().toISOString());

    await new Promise((resolve) => setTimeout(resolve, 1500));
    // toggleMsgLoader();
    setTyping(true);

    try {
      const res = await axios.post(
        "/api/chat-message",
        {
          owner: user.id,
          message: newMessage,
          chatID: chatID,
        },
        {
          timeout: 75000,
        }
      );

      const data = normalizeData(res.data);

      if (data.message)
        addMessage(data.message, false, data.status, data.createdAt);

      if (data.chat) setChat(data.chat);

      if (data.pic_required && data.pic_short_description) {
        let picRes = await axios.post("/api/chat-image", {
          description: data.message,
          short_description: data.pic_short_description,
          chatID: chatID,
        });
        let picData = normalizeData(picRes.data);
        addInAppMessage(Img, {
          src: picData.image.url,
        });
      }
      refreshUser();
      //   toggleMsgLoader();
      setTyping(false);
    } catch (error) {
      console.log(error);
      //   toggleMsgLoader();
      setTyping(false);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100%",
      }}
    >
      <AppPageLayout>
        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            mb: 10,
          }}
        >
          {inAppMessages.map((message, index) => {
            const Component = message.component;
            const props = message.props;
            return <Component key={index} {...props} />;
          })}
          <div ref={chatContainerRef} />
          {typing && (
            <Card
              sx={{
                padding: 2,
                float: "left",
                ml: 2,
                borderRadius: 5,
              }}
              elevation={5}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Loading type="hearts" width={50} height={50} fill="#ff6699" />
              </Grid>
            </Card>
          )}
        </Grid>
      </AppPageLayout>
      <UserInput sendMessage={handleNewUserMessage} disabled={typing} />
    </div>
  );
};

export default Chat;
