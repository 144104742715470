import { Grid } from "@mui/material";

const AppPageLayout = ({ children }) => {
  return (
    <Grid
      container
      maxHeight="100vh"
      overflow="scroll"
      sx={{
        px: 2,
        paddingTop: 7,
      }}
    >
      {children}
    </Grid>
  );
};

export default AppPageLayout;
