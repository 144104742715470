import { useState } from "react";

import { Grid, Card, Box, Dialog, IconButton, Icon } from "@mui/material";

import AppPageLayout from "components/AppPageLayout";

import useAuth from "contexts/AuthContext";
import axios from "utils/axios";

function Img({ image }) {
  const { user, refreshUser } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Grid item key={image.id} sx={{ height: 150, width: 100, m: 1 }}>
      <Card
        sx={{ width: "100%", height: "100%" }}
        elevation={3}
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={image.url}
        />
      </Card>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={{ zIndex: 9999 }}
      >
        <IconButton
          sx={{
            backgroundColor: "#ff6699",
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 9999,
          }}
          onClick={async () => {
            await axios.delete("/api/favourite-images/" + image.id);
            refreshUser();
          }}
        >
          <Icon sx={{ color: "white" }}>delete</Icon>
        </IconButton>
        <img
          style={{ width: "100%", objectFit: "contain" }}
          alt="full"
          src={image.url}
        />
      </Dialog>
    </Grid>
  );
}

const Gallery = () => {
  const { user } = useAuth();

  return (
    <AppPageLayout>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          width: "100%",
          justifyItems: "center",
          alignItems: "center",
        }}
        mt={3}
        mb={10}
      >
        {(user?.favourite_images || []).map((image) => (
          <Img key={image.id} image={image} />
        ))}
      </Box>
    </AppPageLayout>
  );
};

export default Gallery;
