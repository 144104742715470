import { Grid, Typography } from "@mui/material";

import AppPageLayout from "components/AppPageLayout";

const Dating = () => {
  return (
    <Grid container width="100%">
      <AppPageLayout>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="85vh"
          p={3}
        >
          <img
            src="work-in-progress.png"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Grid>
      </AppPageLayout>
    </Grid>
  );
};

export default Dating;
