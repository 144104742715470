import { useState, useEffect } from "react";

import useAuth from "contexts/AuthContext";
import { useHistory } from "react-router-dom";

import { Grid, Button, Typography, Card, Divider } from "@mui/material";
import AppPageLayout from "components/AppPageLayout";

const ProfilePage = () => {
  const { user, logout, refreshUser } = useAuth();
  const history = useHistory();

  const [loadedUser, setLoadedUser] = useState(false);
  const [maxMessages, setMaxMessages] = useState(0);

  useEffect(() => {
    if (user && !loadedUser) {
      refreshUser();
      switch (user?.subscription) {
        case "supporter":
          setMaxMessages(500);
          break;
        case "fan":
          setMaxMessages(1000);
          break;
        case "patron":
          setMaxMessages(2000);
          break;
        default:
          setMaxMessages(0);
          break;
      }
      setLoadedUser(true);
    }
  }, [user]);

  return (
    <AppPageLayout>
      <Grid item xs={12} mt={3} container rowSpacing={5}>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Typography variant="h4">My Profile</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="h6">Email: {user?.email}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Typography variant="h4">My Subscription</Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="h5">Tier: {user?.subscription}</Typography>
            <Typography variant="h6">
              Messages: {user?.credits} / {maxMessages}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ mt: 2 }}
            fullWidth
            variant="contained"
            onClick={() => {
              logout();
              history.push("/");
            }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </AppPageLayout>
  );
};

export default ProfilePage;
