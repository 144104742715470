import React, { useState, useEffect } from "react";

import { Typography, Button, Grid } from "@mui/material";

import AppPageLayout from "components/AppPageLayout";

import { useHistory } from "react-router-dom";

function TOS() {
  const history = useHistory();

  return (
    <AppPageLayout>
      <Grid item xs={12} sx={{ p: 1, mb: 3 }}>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="h3">Terms of Service</Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="h4">Safety Use</Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="body1">
            The software is provided "as is", without warranty of any kind,
            express or implied, including but not limited to the warranties of
            merchantability, fitness for a particular purpose and
            noninfringement. In no event shall the authors or copyright holders
            be liable for any claim.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="body1">
            Additionally, no tolerance of any illegal activity will be
            tolerated. Any illegal activity will be reported to the proper
            authorities. This includes but is not limited to: - Attempt to
            generate child pornography - Attempt to generate illegal materials -
            Attempt to abuse the AI with physical or mental harm Any and all
            other ethically questionable activities will be reported to the
            author of this website and appropriate action will be taken.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="body1">
            A shorter version of the above is: This is meant to be fun, pleas do
            not make it NOT fun or I will have to remove you from the website.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="h4">Tips and Reccommendations</Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="body1">
            This AI is still in development, and as such, it is not perfect. To
            make the best use of it, please follow these tips: - Try and dictate
            the pace of the conversation. If you are jumping between topics too
            much, you won't get a good response. - Try and keep the conversation
            on topic. If you are talking about something, try and keep it on
            that topic. - Try and be proactive, if you'd like something to
            happen, ask for it, but don't push it too much. - Try and follow the
            AI's response, it may go to places you did not foresee but that you
            might enjoy.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        xs={12}
        sx={{ p: 1, mb: 10 }}
      >
        <Button onClick={() => history.push("/profile")} variant="contained">
          Understood
        </Button>
      </Grid>
    </AppPageLayout>
  );
}

export default TOS;
