import { useState, useMemo, useEffect } from "react";

import { Grid, Typography, Alert } from "@mui/material";

import AppPageLayout from "components/AppPageLayout";

import { useLocation, useHistory } from "react-router-dom";
import useAuth from "contexts/AuthContext";
import axios from "utils/axios";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const CompleteLogin = () => {
  const history = useHistory();
  const query = useQuery();

  const { setSession, refreshUser } = useAuth();

  const [status, setStatus] = useState("Logging in...");
  const [errorMessage, setErrorMessage] = useState(null);

  const loginUser = async (code) => {
    try {
      const response = await axios.get("/api/patreon-callback?code=" + code);
      const { jwt, user } = response.data;
      setSession(jwt);
      await refreshUser();
      setStatus("Logged in! Redirecting...");
      history.push("/tos");
    } catch (error) {
      console.log(error);
      switch (error.error.message) {
        case "patreon_error":
          setStatus("Patreon Auth Error.");
          setErrorMessage("Error logging in with Patreon. Please try again.");
          break;
        case "not_member":
          setStatus("Not a Member.");
          setErrorMessage(
            "You are not a member of the Patreon. Please join the Patreon and try again."
          );
          break;
        default:
          setStatus("Error logging in. Please go back and try again.");
          setErrorMessage("An Unspecified error occurred. Please try again.");
          break;
      }
    }
  };

  useEffect(() => {
    const code = query.get("code");
    if (code) {
      loginUser(code);
    }
  }, [query]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="85vh"
      p={3}
    >
      <Grid item xs={10}>
        <Typography variant="h3" align="center">
          {status}
        </Typography>
      </Grid>

      <Grid item xs={10} md={7}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Grid>
    </Grid>
  );
};

export default CompleteLogin;
