import React, { useState, useEffect } from "react";

import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Avatar,
  ListItemIcon,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import AppPageLayout from "components/AppPageLayout";

import axios from "utils/axios";
import { normalizeData } from "utils/utils";

import { useHistory } from "react-router-dom";
import useAuth from "contexts/AuthContext";

function ChatList() {
  const history = useHistory();
  const { user, refreshUser } = useAuth();

  const [chats, setChats] = useState([]);
  const [creating, setCreating] = useState(false);

  // admin stuff
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const getUsers = async () => {
    let { data } = await axios.get(
      "/api/users?populate=*&limit=10000&sort=createdAt:desc"
    );
    let norm = normalizeData(data);
    setUsers(norm);
  };

  useEffect(() => {
    if (user && user.role.name != "Admin") {
      setSelectedUser(user);
      setChats(user.chats || []);
    } else if (user && user.role.name == "Admin") {
      getUsers();
    }
  }, [user]);

  return (
    <AppPageLayout>
      {/* Select User */}
      {user && user.role?.name == "Admin" && !selectedUser && (
        <Grid item xs={12} sx={{ p: 1, mb: 10 }}>
          <List sx={{ width: "100%" }}>
            {users.map((user) => (
              <ListItemButton
                key={user.id}
                onClick={() => {
                  setSelectedUser(user);
                  setChats(user.chats || []);
                }}
              >
                <Typography>{user.email}</Typography>
              </ListItemButton>
            ))}
          </List>
        </Grid>
      )}

      {/* List Chats */}
      {selectedUser && (
        <Grid item xs={12} sx={{ p: 1, mb: 10 }}>
          <List sx={{ width: "100%" }}>
            {chats.map((chat, index) => (
              <ListItemButton
                key={chat.id}
                onClick={() => {
                  window.localStorage.setItem("chatID", chat.id);
                  history.push("/chat");
                }}
              >
                <ListItemIcon>
                  <Avatar src="miku_couch.png" />
                </ListItemIcon>
                <Typography>
                  Sam: {index + 1} - {chat.updatedAt.slice(0, 10)} :{" "}
                  {chat.updatedAt.slice(11, 16)}
                </Typography>
              </ListItemButton>
            ))}
            <ListItem sx={{ my: 4 }}>
              <LoadingButton
                fullWidth
                loading={creating}
                onClick={async () => {
                  setCreating(true);
                  try {
                    const res = await axios.post("/api/chats", {
                      data: {
                        owner: user.id,
                      },
                    });
                    await refreshUser();
                    window.localStorage.setItem("chatID", res.data.id);
                    history.push("/chat");
                  } catch (error) {
                    console.log(error);
                  }
                  setCreating(false);
                }}
              >
                Create
              </LoadingButton>
            </ListItem>
          </List>
        </Grid>
      )}
    </AppPageLayout>
  );
}

export default ChatList;
