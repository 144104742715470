import { useState } from "react";
import useAuth from "contexts/AuthContext";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Link, useLocation } from "react-router-dom";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ImageIcon from "@mui/icons-material/Image";
import MessagesIcon from "@mui/icons-material/Chat";
import Paper from "@mui/material/Paper";

const BottomNav = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [tab, setTab] = useState(0);

  if (!user) return null;

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={location.pathname.split("/")[1]}
        onChange={(event, newValue) => {
          setTab(newValue);
        }}
        sx={{
          backgroundColor: "background.paper",
        }}
      >
        <BottomNavigationAction
          component={Link}
          label="Chats"
          to="/chats"
          value="chats"
          icon={<MessagesIcon />}
        />
        <BottomNavigationAction
          component={Link}
          label="Gallery"
          to="/gallery"
          value="gallery"
          icon={<ImageIcon />}
        />
        <BottomNavigationAction
          component={Link}
          label="Dating"
          to="/dating"
          value="dating"
          icon={<FavoriteIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
