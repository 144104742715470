import { useState } from "react";

// mui
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// utils
import useAuth from "contexts/AuthContext";
import axios from "utils/axios";

const LoginForm = ({ onComplete, onError }) => {
  const { login } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    attemptLogin(email, password);
  };

  const attemptLogin = async (email, password) => {
    try {
      let newUser = await login(email, password);
      if (newUser != null) {
        if (onComplete) onComplete();
      }
    } catch (error) {
      console.log(error);
      if (onError) onError(error.error);
    }
  };

  return (
    <Grid item>
      <Card sx={{ p: 5 }} elevation={0}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/password-forgot" variant="body2">
                Forgot Password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};

const RegisterForm = ({ onComplete, onError }) => {
  const { register } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let username = data.get("username");
    let email = data.get("email");
    let password = data.get("password");
    attemptRegistration(username, email, password);
  };

  const attemptRegistration = async (username, email, password) => {
    if (typeof window !== "undefined") {
      try {
        let newUser = await register(username, email, password);
        if (newUser != null) {
          if (onComplete) onComplete();
        }
      } catch (error) {
        console.log(error);
        if (onError) onError(error.error);
      }
    }
  };

  return (
    <Grid item>
      <Card sx={{ p: 5 }} elevation={0}>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="given-name"
                name="username"
                required
                fullWidth
                id="username"
                label="Username"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Register
          </Button>
        </Box>
      </Card>
    </Grid>
  );
};

// oauth patreon login
const PatreonLogin = ({ onComplete, onError }) => {
  const handlePatreonLogin = async (e) => {
    e.preventDefault();
    try {
      let code = await loginWithPatreon();
      console.log("LOGGED IN!", code);
      // if (newUser != null) {
      //   if (onComplete) onComplete();
      // }
    } catch (error) {
      console.log(error);
      if (onError) onError(error.error);
    }
  };

  const loginWithPatreon = async () => {
    try {
      const response = await axios.get("/api/patreon-login");
      const data = await response.data;
      console.log(data);
      window.open(data, "_self");
    } catch (error) {
      console.log(error);
      // window.open(error, "_blank");
    }
  };

  return (
    <Grid item>
      <Card sx={{ p: 5, borderRadius: 5 }} elevation={2}>
        <Typography component="h1" variant="h5">
          Patreon Login
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handlePatreonLogin}
          sx={{ mt: 3 }}
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login with Patreon
          </Button>
        </Box>
      </Card>
    </Grid>
  );
};

export default function Login({
  onComplete,
  onError,
  login,
  patreon,
  registerDisabled,
}) {
  const [isLogin, setIsLogin] = useState(login);

  return (
    <Grid item sx={{ maxHeight: "50vh" }}>
      {/* <Grid item xs={12} container justifyContent="space-evenly">
        <Grid item xs={6}>
          <Button fullWidth onClick={() => setIsLogin(true)} disabled={isLogin}>
            Login
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            onClick={() => setIsLogin(false)}
            disabled={!isLogin}
          >
            Register
          </Button>
        </Grid>
      </Grid> */}
      {patreon && <PatreonLogin onComplete={onComplete} onError={onError} />}
      {!patreon && (
        <>
          {isLogin ? (
            <LoginForm onComplete={onComplete} onError={onError} />
          ) : registerDisabled ? null : (
            <RegisterForm onComplete={onComplete} onError={onError} />
          )}
        </>
      )}
    </Grid>
  );
}
