import React, { useState, useEffect } from "react";
import "./App.css";

import {
  Grid,
  Dialog,
  Card,
  Typography,
  List,
  ListItemButton,
  Chip,
} from "@mui/material";

import LoginOrRegister from "components/LoginOrRegister";
import { useHistory } from "react-router-dom";

function App() {
  const history = useHistory();

  return (
    <Grid
      container
      width="100%"
      sx={{ height: "100vh", maxHeight: "100vh", overflow: "hidden" }}
    >
      <Grid item xs={12} sx={{ height: "50vh", width: "100%" }}>
        <img
          src="miku_couch.png"
          alt="Kindly"
          style={{ height: "50vh", width: "100%", objectFit: "cover" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ height: "50vh", width: "100%" }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <LoginOrRegister
          patreon
          // onComplete={() => {
          //   history.push("/chats");
          // }}
        />
      </Grid>
    </Grid>
  );
}

export default App;
