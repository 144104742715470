import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import CssBaseline from "@mui/material/CssBaseline";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { AuthProvider } from "contexts/AuthContext";

import App from "./App";
import ExtLogin from "pages/ExtLogin";
import CompleteLogin from "pages/CompleteLogin";
import TOS from "pages/TOS";
import ChatList from "pages/ChatList";
import Chat from "pages/Chat";
import ProfilePage from "pages/ProfilePage";
import Gallery from "pages/Gallery";
import Dating from "pages/Dating";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";

import BottomNav from "components/BottomNav";
import SideMenu from "components/SideMenu";

import { ThemeProvider, createTheme } from "@mui/material/styles";

export const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#b53fa6",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      paper: "#1c1c1c",
    },
  },
  typography: {
    fontFamily: "Mukta",
    fontSize: 14,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          background: "linear-gradient(45deg, #b53fa6 30%, #f50057 90%)",
          border: 0,
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
          color: "white",
          height: 48,
          padding: "0 30px",
        },
      },
    },
  },
  props: {
    MuiButton: {
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
    },
    MuiSwitch: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
  },
};

const theme = createTheme(themeOptions);

const routes = [
  { path: "/", component: App, showMenu: true, showNav: true },
  {
    path: "/complete-login",
    component: CompleteLogin,
    showMenu: false,
    showNav: false,
  },
  { path: "/login-ext", component: ExtLogin, showMenu: true, showNav: true },
  { path: "/tos", component: TOS, showMenu: false, showNav: false },
  { path: "/chats", component: ChatList, showMenu: true, showNav: true },
  { path: "/chat", component: Chat, showMenu: true, showNav: false },
  { path: "/profile", component: ProfilePage, showMenu: true, showNav: true },
  { path: "/gallery", component: Gallery, showMenu: true, showNav: true },
  { path: "/dating", component: Dating, showMenu: true, showNav: true },
];

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <AuthProvider>
        <BrowserRouter>
          <Route
            render={({ location }) => {
              return (
                <>
                  <PageTransition
                    preset="slide"
                    transitionKey={location.pathname}
                  >
                    <Switch location={location}>
                      {routes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact
                          component={route.component}
                        />
                      ))}
                    </Switch>
                  </PageTransition>
                  {routes.find((x) => x.path == location.pathname)
                    ?.showMenu && <SideMenu />}
                  {routes.find((x) => x.path == location.pathname)?.showNav && (
                    <BottomNav />
                  )}
                </>
              );
            }}
          />
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
